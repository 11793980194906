import { AbstractControl } from '@angular/forms';
import { cpfValido } from 'src/app/util/bibioteca';

export class CpfValidator {



  static validate(control: AbstractControl): { [key: string]: boolean } {

    if (cpfValido(control.value)) {
      return null;
    }
    return { cpf: true };
  }



}
