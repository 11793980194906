import { BaseModel } from "./base-model";

export class Empresa extends BaseModel {
  id: number;
  razaoSocial: string;
  nomeFantasia: string;
  cnpj: string;
  inscricaoEstadual: string;
  inscricaoEstadualSt: string;
  inscricaoMunicipal: string;
  imagemLogotipo?: string;
  endereco: any;
  email: string;
  senha: string;
  fone: string;
  logo: string;

  static fromJson(jsonData: any): Empresa {
    return Object.assign(new Empresa(), jsonData);
  }
}
