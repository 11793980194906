import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "chronos-pdv";
  toogleToobar = false;
  menuClick = false;
  menuMobileActive = false;

  constructor(private router: Router) {}

  get isDysplayLogin(): boolean {
    return this.router.url === "/login";
  }

  onToggle(event) {
    this.menuClick = true;

    if (this.isDesktop()) {
    } else {
      this.menuMobileActive = !this.menuMobileActive;
    }
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  isMobile() {
    return window.innerWidth <= 640;
  }

  onLayoutClick() {
    if (!this.menuClick) {
      if (this.menuMobileActive) {
        this.menuMobileActive = false;
      }
    }

    this.menuClick = false;
  }

  onMenuClick(event) {
    this.menuClick = true;
    this.menuMobileActive = false;
  }

  get isDysplayPrint(): boolean {
    return this.router.url.includes("/print/");
  }
}
