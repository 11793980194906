import { AfterContentInit, Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FooterComponent } from './card-footer.componet';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit,AfterContentInit {

  @Input() title = '';
  @Input() showButtonAction = false;
  @Input() labelButtonAction = 'Novo';

  @Output() eventClickAction = new EventEmitter();

  @ContentChild(FooterComponent) footer;

  footerTemplate: TemplateRef<any>;

  constructor() { }


  ngOnInit() {
  }

  ngAfterContentInit(): void {

  }

  onClick() {
    this.eventClickAction.emit();
  }

}
