import { Params } from './../models/params';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ChronosHttp } from "src/app/security/chronos-http";
import { AuthService } from "src/app/security/services/auth-service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ParamsService {

  url: string;

  constructor(private http: ChronosHttp, private auth: AuthService) {
    this.url = `${environment.apiUrl}/configuracoes/parametro/empresa`;
  }

  getParams(idCompany: number): Observable<Params> {
    return this.http.get<Params>(`${this.url}/${idCompany}`);
  }
}
