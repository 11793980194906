import {
  AfterContentInit,
  Component,
  ContentChild,
  Input,
  OnInit,
} from "@angular/core";
import { FormControlName, NgModel } from "@angular/forms";

@Component({
  selector: "app-container-input",
  templateUrl: "./container-input.component.html",
  styleUrls: ["./container-input.component.scss"],
})
export class ContainerInputComponent implements OnInit, AfterContentInit {
  @Input() label: string;
  @Input() msg: string;
  @Input() info: string;

  @Input() classe: string;

  @Input() required = false;
  @ContentChild(NgModel, { static: true }) model: NgModel;
  @ContentChild(FormControlName, { static: true }) control: FormControlName;

  input: NgModel | FormControlName;

  constructor() {}

  ngOnInit() {}

  ngAfterContentInit() {
    this.input = this.model || this.control;

    if (this.input === undefined) {
      throw new Error(
        "Esse componente precisa ser usado com uma diretiva ngModel ou formControlName"
      );
    }

    const erros = Boolean(this.input.control)
      ? this.input.control.errors
      : null;
    if (erros && (erros.required || erros.obrigatoriedade)) {
      this.required = true;
    }
  }

  hasSuccess(): boolean {
    return this.input.valid && (this.input.dirty || this.input.touched);
  }

  hasError(): boolean {
    return this.input.invalid && (this.input.dirty || this.input.touched);
  }

  get mensagemErro(): string {
    if (this.msg) {
      return this.msg;
    } else {
      if (this.input instanceof FormControlName) {
        return this.verificarMensagem(this.input.control.errors);
      } else {
        return `${this.label} obrigatorio`;
      }
    }
  }

  verificarMensagem(objeto: any): string {
    if (objeto.obrigatoriedade) {
      return `${this.label} obrigatorio`;
    } else if (objeto.tamanhoMaximo) {
      return `tamanho máximo de ${objeto.tamanhoMaximo.tamanho}`;
    } else {
      return `${this.label} obrigatorio`;
    }
  }

  get colunaDefinida(): boolean {
    return Boolean(this.classe);
  }
}
