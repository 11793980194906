import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChronosHttp } from 'src/app/security/chronos-http';
import { ClienteCad } from 'src/app/shared/cliente-cad';
import { Cliente } from 'src/app/shared/models/cliente';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  url: string;

  constructor(private http: ChronosHttp) {
    this.url = `${environment.apiUrl}/cadastros/comercial/clientes`;
  }


  finByNameOrId(name: string): Promise<Array<any>> {

    const params = new HttpParams({
      fromObject: { filter: name }
    });

    return this.http.get<Array<any>>(`${this.url}/nome/`, { params })
      .toPromise();
  }

  findByIdWithFinancial(id: number): Promise<Cliente> {
    return this.http.get<Cliente>(`${this.url}/${id}/situacao-financeira`)
      .toPromise();
  }

  createClient(client: ClienteCad): Promise<ClienteCad> {
    return this.http.post<ClienteCad>(`${this.url}/resum/`, client)
      .toPromise();
  }

}
