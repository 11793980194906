<div class="card">
  <div class="card-title p-d-flex" *ngIf="title.length > 0 || showButtonAction">
    <h1>{{title}}</h1>
    <button type="button" pButton [label]="labelButtonAction" (click)="onClick()" *ngIf="showButtonAction"></button>
  </div>
  <div class="card-content">
    <ng-content></ng-content>
  </div>

  <div class="card-footer" *ngIf="footer">
    <ng-content select="app-footer"></ng-content>
  </div>
</div>
