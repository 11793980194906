import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { PdvVendaCabecalhoDTO } from "src/app/shared/models/venda";
import { SaleService } from "../../pdv/services/sale.service";

@Injectable({
  providedIn: "root",
})
export class SaleResolveService implements Resolve<PdvVendaCabecalhoDTO> {
  constructor(private service: SaleService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | PdvVendaCabecalhoDTO
    | Promise<PdvVendaCabecalhoDTO>
    | Observable<PdvVendaCabecalhoDTO> {
    const id = route.params["id"];

    return this.service.findById(id);
  }
}
