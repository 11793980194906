import { Injectable } from '@angular/core';
import { ChronosHttp } from 'src/app/security/chronos-http';
import { environment } from 'src/environments/environment';
import { PdvConfiguracao } from '../../../shared/models/pdv-configuracao.model';

@Injectable({
  providedIn: 'root'
})
export class PdvConfigurationService {

  url: string;

  constructor(private http: ChronosHttp) {
    this.url = `${environment.apiUrl}/configuracoes/pdv`;

  }

  findByCaixa(idcaixa: number): Promise<PdvConfiguracao> {
    return this.http.get<PdvConfiguracao>(`${this.url}/caixa/${idcaixa}`).toPromise();
  }
}
