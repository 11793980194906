import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ErrorHandlerService } from "src/app/shared/services/error-handler.service";
import { PdvConfigurationService } from "./../../../view/cashier/services/pdv-configurarion.service";

import { StorageKeys } from "src/app/storage-keys";
import { MovementService } from "src/app/view/cashier/services/movement.service";
import { AuthService } from "../../services/auth-service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  submitting = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    public auth: AuthService,
    private movimentService: MovementService,
    private configuracaoService: PdvConfigurationService,
    private errorHandle: ErrorHandlerService
  ) {}

  ngOnInit() {
    this.createForm();

    const userData = this.auth.getRememberMe();
    if (userData) {
      this.email.setValue(userData.email);
      this.password.setValue(userData.password);
    }
  }

  async onSubmit() {
    try {
      this.submitting = true;

      await this.auth.login(
        this.loginForm.value.email,
        this.loginForm.value.password
      );

      this.auth.setRememberMe(this.loginForm.value);

      const result = await this.movimentService.findMovement(
        this.auth.jwtPayload.idoperador
      );

      localStorage.setItem(StorageKeys.MOVIMENT, "true");
      localStorage.setItem(StorageKeys.MOVIMENT_KEY, result.id.toString());

      const config = await this.configuracaoService.findByCaixa(result.idcaixa);

      localStorage.setItem(StorageKeys.CONFIG, JSON.stringify(config));

      this.router.navigate(["/sales"]);
    } catch (error) {
      if (!error.status && error.status !== 404) {
        this.errorHandle.handleError(error);
        this.auth.clearAccessToken();
      } else {
        this.errorHandle.handleError(error);
      }
    } finally {
      this.submitting = false;
    }
  }

  onRememberMe(): void {
    this.auth.toggleRememberMe();
  }

  get email(): UntypedFormControl {
    return this.loginForm.get("email") as UntypedFormControl;
  }
  get password(): UntypedFormControl {
    return this.loginForm.get("password") as UntypedFormControl;
  }

  private createForm(): void {
    this.loginForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(5)]],
      rememberme: [false],
    });
  }
}
