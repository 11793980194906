import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ChronosHttp } from "src/app/security/chronos-http";
import { MovimentoDetalhado } from "src/app/shared/models/moviment-detalhado";
import { MovimentoCriarRequest } from "src/app/shared/models/movimento-criar-request";
import { Movimento } from "src/app/shared/models/movimento.model";
import { StorageKeys } from "src/app/storage-keys";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MovementService {
  url: string;
  openMoviment = false;
  keyMoviment = -1;

  constructor(private http: ChronosHttp, private router: Router) {
    this.url = `${environment.apiUrl}/comercial/movimentos-caixa`;
    this.verifyMoviment();
  }

  verifyMoviment() {
    this.openMoviment = JSON.parse(
      window.localStorage.getItem(StorageKeys.MOVIMENT)
    );
    this.keyMoviment = JSON.parse(
      window.localStorage.getItem(StorageKeys.MOVIMENT_KEY)
    );
  }

  async getKeyMoviment(idoperador: number) {
    const key = JSON.parse(
      window.localStorage.getItem(StorageKeys.MOVIMENT_KEY)
    );

    if (!Boolean(key)) {
      try {
        const result = await this.findMovement(idoperador);
        localStorage.setItem(StorageKeys.MOVIMENT, "true");
        localStorage.setItem(StorageKeys.MOVIMENT_KEY, result.id.toString());
        return result.id;
      } catch (err) {
        this.router.navigate(["/caixa"]);
      }
    } else {
      return key;
    }
  }

  isOpen(): boolean {
    this.openMoviment = JSON.parse(
      window.localStorage.getItem(StorageKeys.MOVIMENT)
    );
    this.keyMoviment = JSON.parse(
      window.localStorage.getItem(StorageKeys.MOVIMENT_KEY)
    );
    return this.openMoviment;
  }

  findMovementById(): Promise<Movimento> {
    return this.http
      .get<Movimento>(`${this.url}/${this.keyMoviment}`)
      .toPromise();
  }

  async findMovement(idoperador: number): Promise<Movimento> {
    return this.http
      .get<Movimento>(`${this.url}/operador/${idoperador}`)
      .toPromise();
  }

  findDetail(): Promise<Array<MovimentoDetalhado>> {
    return this.http
      .get<Array<MovimentoDetalhado>>(
        `${this.url}/${this.keyMoviment}/lancamentos`
      )
      .toPromise();
  }

  createMovement(movement: MovimentoCriarRequest): Promise<Movimento> {
    const newMoviment = JSON.parse(JSON.stringify(movement));

    if (movement.empresa) {
      newMoviment.idempresa = movement.empresa.id;
      delete newMoviment.empresa;
    }

    if (movement.caixa) {
      newMoviment.idcaixa = movement.caixa.id;
      delete newMoviment.caixa;
    }

    if (movement.operador) {
      newMoviment.idoperador = movement.operador.id;
      newMoviment.idGerenteSupervisor = newMoviment.idoperador;
      delete newMoviment.operador;
    }

    if (movement.turno) {
      newMoviment.idturno = movement.turno.id;
      delete newMoviment.turno;
    }

    return this.http.post<Movimento>(this.url, newMoviment).toPromise();
  }

  confirmClosing(): Promise<Movimento> {
    return this.http
      .put<Movimento>(`${this.url}/fechamento/${this.keyMoviment}`, "F")
      .toPromise();
  }

  clearMovement() {
    localStorage.removeItem(StorageKeys.MOVIMENT);
    localStorage.removeItem(StorageKeys.MOVIMENT_KEY);
    localStorage.removeItem(StorageKeys.CONFIG);
    this.router.navigate(["/caixa"]);
  }
}
