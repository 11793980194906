export function printReport(
  elemento: HTMLElement,
  openNewWindows = false,
  showPrint = false
) {
  const mywindow = openNewWindows
    ? window.open("", "_blank")
    : window.open("", "_self");

  let globalCss =
    "* {margin: 0;padding: 0;outline: 0;box-sizing: border-box;} ";
  globalCss += "html, body{height: 100%;} ";
  globalCss +=
    "body {font: 14px Arial, sans-serif;-webkit-font-smoothing: antialiased !important;} ";
  globalCss +=
    "@page {margin: 0;padding: 0;outline: 0;box-sizing: border-box;} ";
  globalCss +=
    ".text-center{text-align: center} .text-right{text-align: right}";

  globalCss += "@media print { .btn-print{display:none} }";

  const css =
    ".container{width: 385px;height: auto;background: #FFFFFF;margin: 0 auto;padding: 25px;} ";

  mywindow.document.write("<html><head> <title>" + document.title + "</title>");
  mywindow.document.write('<style type="text/css">');
  mywindow.document.write(globalCss);
  mywindow.document.write(css);
  mywindow.document.write("</style>");
  mywindow.document.write("</head><body >");
  mywindow.document.write('<div class="container"> ');
  mywindow.document.write(elemento.innerHTML);
  mywindow.document.write("</div> ");
  mywindow.document.write("</body></html>");

  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/

  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/

  if (showPrint) mywindow.print();
}
