import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/security/services/auth-service';
import { environment } from 'src/environments/environment';




@Component({
  selector: 'app-layout-menu',
  templateUrl: './layout-menu.component.html',
  styleUrls: ['./layout-menu.component.css']
})
export class LayoutMenuComponent implements OnInit {

  @Input() displayLogin = true;

  days = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
  day = new Date();

  version = environment.version;

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit() {

  }

  navegateByUrl(url) {
    this.router.navigate([url]);
  }

  logout() {
    this.authService.logout().then(() => this.router.navigate(['/login']));
  }



}
