import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./services/auth-service";

@Injectable({
  providedIn: "root",
})
export class AutoLoginGuard implements CanActivate {
  constructor(private service: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (next.url[0].path === "login" && !this.service.isAccessTokenInvalid()) {
      this.router.navigate(["/sales"]);
    }

    return true;
  }
}
