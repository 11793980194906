<p-dialog
  header="Cadastro Rapido de Cliente"
  [(visible)]="display"
  [modal]="true"
  [responsive]="true"
  [style]="{ minWidth: '360px' }"
  [minY]="70"
  [contentStyle]="{
    overflow: 'visible',
    'max-width': '800px',
    'max-height': '80vh',
    'min-height': '250px'
  }"
  (onHide)="closer()"
>
  <form #f="ngForm" autocomplete="off" (submit)="save()">
    <div class="p-fluid p-formgrid p-grid">
      <app-container-input label="Nome" classe="p-field p-col-12 p-md-8 p-lg-8">
        <input
          type="text"
          pInputText
          [(ngModel)]="client.nome"
          name="nome"
          required
        />
      </app-container-input>

      <app-container-input label="Tipo" classe="p-field p-col-12 p-md-4 p-lg-4">
        <p-dropdown
          [options]="typePesson"
          [(ngModel)]="client.tipoPessoa"
          name="tipoPessoa"
        ></p-dropdown>
      </app-container-input>

      <app-container-input
        label="CPF"
        classe="p-field p-col-12 p-md-4 p-lg-4"
        *ngIf="client.tipoPessoa === 'FISICA'"
      >
        <p-inputMask
          name="cpfCnpj"
          mask="999.999.999-99"
          appCpf
          #cpf="ngModel"
          [(ngModel)]="client.cpfCnpj"
          unmask="true"
        >
        </p-inputMask>
      </app-container-input>

      <app-container-input
        label="CNPJ"
        classe="p-field p-col-12 p-md-4 p-lg-4"
        *ngIf="client.tipoPessoa === 'JURIDICA'"
      >
        <p-inputMask
          name="cpfCnpj"
          mask="99.999.999/9999-99"
          appCnpj
          #cpf="ngModel"
          [(ngModel)]="client.cpfCnpj"
          unmask="true"
        >
        </p-inputMask>
      </app-container-input>

      <app-container-input label="Fone" classe="p-field p-col-12 p-md-4 p-lg-4">
        <p-inputMask
          name="fone"
          mask="(99)99999-9999"
          [(ngModel)]="client.fone"
        ></p-inputMask>
      </app-container-input>

      <app-container-input
        label="Email"
        classe="p-field p-col-12 p-md-4 p-lg-4"
      >
        <input type="text" pInputText [(ngModel)]="client.email" name="email" />
      </app-container-input>

      <app-container-input
        label="Endereço"
        classe="p-field p-col-12 p-md-10 p-lg-10"
      >
        <input
          type="text"
          pInputText
          [(ngModel)]="client.logradouro"
          name="logradouro"
        />
      </app-container-input>

      <app-container-input label="Nº" classe="p-field p-col-12 p-md-2 p-lg-2">
        <input
          type="text"
          pInputText
          [(ngModel)]="client.numero"
          name="numero"
        />
      </app-container-input>

      <app-container-input label="CEP" classe="p-field p-col-12 p-md-4 p-lg-4">
        <input
          type="text"
          pInputText
          [(ngModel)]="client.cep"
          name="cep"
          maxlength="8"
        />
      </app-container-input>
      <app-container-input
        label="Bairro"
        classe="p-field p-col-12 p-md-4 p-lg-4"
      >
        <input
          type="text"
          pInputText
          [(ngModel)]="client.bairro"
          name="bairro"
        />
      </app-container-input>
      <app-container-input
        label="Complemento"
        classe="p-field p-col-12 p-md-4 p-lg-4"
      >
        <input
          type="text"
          pInputText
          [(ngModel)]="client.complemento"
          name="complemento"
        />
      </app-container-input>

      <app-container-input label="UF" classe="p-field p-col-12 p-md-2 p-lg-2">
        <select name="uf" [(ngModel)]="client.uf" class="form-control">
          <option [ngValue]="uf.value" *ngFor="let uf of ufs">
            {{ uf.label }}
          </option>
        </select>
      </app-container-input>

      <app-container-input
        label="Cidade"
        classe="p-field p-col-12 p-md-10 p-lg-10"
      >
        <p-autoComplete
          [(ngModel)]="city"
          field="nome"
          name="city"
          [minLength]="3"
          [suggestions]="cidades"
          [forceSelection]="true"
          (completeMethod)="searchCity($event)"
          [dropdown]="true"
          (onSelect)="citySelected($event)"
          emptyMessage="Nenhum registro localizado"
        >
        </p-autoComplete>
      </app-container-input>
    </div>

    <p-footer>
      <div class="mt-2 p-d-flex p-justify-around">
        <button
          type="submit"
          pButton
          icon="pi pi-check"
          [disabled]="f.invalid"
          label="Salvar"
        ></button>
        <button
          type="button"
          id="botao--cancelar"
          pButton
          icon="pi pi-times"
          (click)="display = false"
          label="Cancelar"
          class="p-button-secondary"
        ></button>
      </div>
    </p-footer>
  </form>
</p-dialog>
