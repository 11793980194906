export class StorageKeys {
  static KEEP_SIGNED = 'pdv-keep-signed';
  static AUTH_TOKEN = 'chronos-auth-token';
  static REMEMBER_ME = 'pdv-remember-me';
  static USER_EMAIL = 'pdv-user-email';
  static USER_PASSWORD = 'pdv-user-password';
  static AUTOCOMPLETE = 'pdv-autocomplete';
  static MOVIMENT = 'pdv-moviment';
  static MOVIMENT_KEY = 'pdv-moviment-key';

  static CONFIG = 'pdv-config';
  static SELLER = 'pdv-seller-key';
}
