<div class="container-menu main-menu">
  <div class="container-logo">
    <a href="https://chronosinfo.com.br" target="blank">
        <img src="assets/images/logo2x.png" class="logo" alt="chronosinfo">
    </a>

  </div>

  <div class="date">
    <p>
      <span class="date-week text">{{days[day.getDay()]}}</span>
    </p>
    <span class="date-complet mb-2">{{day | date:'dd/MM/yyyy'}}</span>
    <strong>versão {{version}}</strong>
  </div>
  <nav class="navbar">

    <ul class="nav-menu">

      <li class="nav-menu-item">
        <a href="http://l.chronoserp.com.br/ChronosERP" target="blank" class="btn-erp" style="height: 40px;line-height: 35px">
          <i class="pi pi-sign-in pi-2x"></i>
          <span class="text">Ir para o ERP</span>
        </a>
      </li>

      <li class="nav-menu-item" routerLinkActive="active" *ngIf="!displayLogin" >
        <a href="javascript:;" class="btn-sair" style="height: 40px;line-height: 35px" (click)="logout()">
          <i class="pi pi-sign-out pi-2x"></i>
          <span class="text">Sair do PDV</span>
        </a>
      </li>

      <li class="nav-menu-item" routerLinkActive="active" *ngIf="!displayLogin">
        <a class="btn-icon btn-sell" routerLink="/pdv">
          <span class="icon"></span>
          Vender
        </a>
      </li>

      <li class="nav-menu-item" routerLinkActive="active" *ngIf="!displayLogin">
        <a class="btn-icon btn-sales" routerLink="/sales">
          <span class="pi pi-tag" style="display:block;padding: 5px;font-size: 2rem;"></span>
          Vendas
        </a>
      </li>

      <li class="nav-menu-item" routerLinkActive="active" *ngIf="!displayLogin">
        <a class="btn-icon btn-budget" routerLink="/budgets">
          <span class="icon"></span>
          Orçamento
        </a>
      </li>

      <li class="nav-menu-item" routerLinkActive="active" *ngIf="!displayLogin">
        <a class="btn-icon btn-financial" routerLink="/receipt">
          <span class="icon"></span>
          Recebimento
        </a>
      </li>

      <li class="nav-menu-item" routerLinkActive="active" *ngIf="!displayLogin">
        <a class="btn-icon btn-operations" routerLink="/operations">
          <span class="pi pi-cog" style="display:block;padding: 5px; font-size: 2rem;"></span>
          <span>Operações</span>
        </a>
      </li>

    </ul>



  </nav>
</div>
