import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { AuthService } from "src/app/security/services/auth-service";
import { ErrorHandlerService } from "../../services/error-handler.service";

@Component({
  selector: "app-login-permission",
  templateUrl: "./login-permission.component.html",
  styleUrls: ["./login-permission.component.scss"],
})
export class LoginPermissionComponent implements OnInit {
  @Input() displayLogin = false;
  @Output() eventTemPermissao = new EventEmitter<boolean>();
  loginForm: UntypedFormGroup;
  submitting = false;
  temPermissao = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public auth: AuthService,
    private errorHandle: ErrorHandlerService
  ) {}

  ngOnInit() {
    this.temPermissao = false;
    this.createForm();
  }

  async onSubmit() {
    try {
      this.submitting = true;

      await this.auth
        .loginAuthorized(
          this.loginForm.value.email,
          this.loginForm.value.password
        )
        .then((e) => {
          this.temPermissao = Boolean(e);
          this.eventTemPermissao.emit(this.temPermissao);
          if (!this.temPermissao) {
            this.errorHandle.handleError(
              "Usuário não tem permissão para realizar essa operação"
            );
          }
        });
    } catch (error) {
      if (!error.status && error.status !== 404) {
        this.errorHandle.handleError(error);
        this.auth.clearAccessToken();
      } else {
        this.errorHandle.handleError(error);
      }
    } finally {
      this.submitting = false;
    }
  }

  get email(): UntypedFormControl {
    return this.loginForm.get("email") as UntypedFormControl;
  }
  get password(): UntypedFormControl {
    return this.loginForm.get("password") as UntypedFormControl;
  }

  private createForm(): void {
    this.loginForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(5)]],
      rememberme: [false],
    });
  }

  onHide() {
    this.eventTemPermissao.emit(this.temPermissao);
    this.temPermissao = false;
  }
}
