import { BaseModel } from './models/base-model';

export class ClienteCad extends BaseModel {
  nome: string;
  cpfCnpj: string;
  tipoPessoa = 'FISICA';
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  uf = 'AC';
  cep: string;
  codigoIbgeCidade: number;
  fone: string;
  email: string;
}
