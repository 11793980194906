import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { CpfValidator } from '../validators/cpf-validator';

@Directive({
  selector: '[appCpf]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CpfDirective,
    multi: true
  }]
})


export class CpfDirective implements Validator {

  /**
   * Valida os dados.
   *
   * @param AbstractControl control
   * @return object ou null caso válido
   */
  validate(control: AbstractControl): {[key: string]: any} {
    return CpfValidator.validate(control);
  }

}
