import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'completeWithZeros'
})
export class CompleteWithZerosPipe implements PipeTransform {

    transform(str: any, lenght: number): string {

        if (!str) {
            return '';
        }

        const newValue = typeof str === 'string' ? str.replace(/\D/g, '') : str;

        return (String('0').repeat(lenght) + newValue).substr((lenght * -1), lenght);
    }

}
