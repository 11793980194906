import { Injectable } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Observable, of } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";
import { ChronosHttp } from "src/app/security/chronos-http";
import { AuthService } from "src/app/security/services/auth-service";
import { environment } from "src/environments/environment";
import { Empresa } from "../models/empresa.model";

@Injectable({
  providedIn: "root",
})
export class EmpresaService {
  url: string;

  private latestValue!: string | SafeUrl;

  constructor(
    private http: ChronosHttp,
    private auth: AuthService,
    private domSanitizer: DomSanitizer
  ) {
    this.url = `${environment.apiUrl}/cadastros/administrativos/empresas`;
  }

  getEmpresa(): Observable<Empresa> {
    return this.http.get<Empresa>(
      `${this.url}/${this.auth.jwtPayload.idempresa}`
    );
  }

  getLogoEmpresa(nameLogo: string) {
    return this.http
      .get<Empresa>(
        `${this.url}/${this.auth.jwtPayload.idempresa}/logo/${nameLogo}`,
        { observe: "response", responseType: "blob" }
      )
      .pipe(
        // we map our blob into an ObjectURL
        map((response: any) => URL.createObjectURL(response.body)),
        // we bypass Angular's security mechanisms
        map((unsafeBlobUrl: string) =>
          this.domSanitizer.bypassSecurityTrustUrl(unsafeBlobUrl)
        ),
        filter((blobUrl) => blobUrl !== this.latestValue),

        // if the request errors out we return the error image's path value
        catchError((error) => of(console.error(error)))
      );
  }
}
