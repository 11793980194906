
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';
import { ChronosHttp } from '../security/chronos-http';
import { SharedModule } from '../shared/shared.module';
import { LayoutContainerComponent } from './components/layout/layout-container/layout-container.component';
import { LayoutMenuComponent } from './components/layout/layout-menu/layout-menu.component';
import { TopBarComponent } from './components/layout/top-bar/top-bar.component';
import { LoadingComponent } from './components/loading/loading.component';
import { BlobErrorHttpInterceptor } from './interception/BlobErrorHttpInterceptor';
import { LoadingIndicatorInterceptor } from './interception/loading-indicator-interceptor';
import { LoadingIndicatorService } from './services/loading-indicator.service';


registerLocaleData(localePt);


@NgModule({

  imports: [
    SharedModule,
    HttpClientModule,
    ProgressSpinnerModule,
    RouterModule
  ],
  declarations: [
    LayoutContainerComponent,
    LayoutMenuComponent,
    LoadingComponent,
    TopBarComponent
  ],
  exports: [
    BrowserAnimationsModule,
    ToastModule,
    LayoutMenuComponent,
    TopBarComponent,
    LoadingComponent,
    ConfirmDialogModule,
    ScrollPanelModule
  ],
  providers: [
    ChronosHttp,
    LoadingIndicatorService,
    Title,
    ConfirmationService,
    MessageService,
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (service: LoadingIndicatorService) => new LoadingIndicatorInterceptor(service),
      multi: true,
      deps: [LoadingIndicatorService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: BlobErrorHttpInterceptor, multi: true },
  ]
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule
  ) {


    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only.');
    }
  }
}
