<div
  [class.has-error]="hasError()"
  class="gap-3"
  [class]="colunaDefinida ? classe : ''"
>
  <label class="control-label" [class.required]="required">{{ label }}</label>
  <i
    class="pi pi-info-circle"
    style="margin-left: 5px"
    [title]="info"
    *ngIf="info"
  ></i>
  <ng-content></ng-content>
  <span class="error-msg help-block m-b-none text-danger" *ngIf="hasError()">
    <i class="fa fa-remove"></i>{{ mensagemErro }}
  </span>
</div>
