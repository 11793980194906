<div class="layout-wrapper">

  <div class="menu">
      <app-layout-menu></app-layout-menu>
  </div>
  <div class="layout-content">
      <router-outlet></router-outlet>
  </div>

</div>


