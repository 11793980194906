import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Base64 } from "js-base64";
import { StorageKeys } from "src/app/storage-keys";
import { RULE_ADMIN } from "src/app/util/rules";
import { environment } from "src/environments/environment";
import { JwtHelperService } from "../jwt-helper";
import { jwtPayload } from "./models/jwt-payload";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  url: string;
  jwtPayload?: jwtPayload;
  rememberMe: boolean;

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService) {
    this.url = `${environment.apiUrl}/oauth/token`;
    this.rememberMe = JSON.parse(
      window.localStorage.getItem(StorageKeys.REMEMBER_ME)
    );
    this.loadToken();
  }

  login(login: string, password: string): Promise<void> {
    const senhaClient = btoa("appweb:w3bcl13nt");
    const headers = new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("Authorization", `Basic ${senhaClient}`);

    const body = `username=${login}&password=${password}&grant_type=password`;

    return this.http
      .post<any>(this.url, body, { headers, withCredentials: true })
      .toPromise()
      .then((response) => {
        if (!response.idoperador) {
          return Promise.reject("Usuário não definido como operador");
        }

        this.storeToken(response.access_token);
      })
      .catch((response) => {
        if (response.status === 400) {
          if (response.error && response.error.error === "invalid_grant") {
            return Promise.reject("Usuário ou senha inválida!");
          }
        }

        return Promise.reject(response);
      });
  }

  loginAuthorized(login: string, password: string): Promise<void> {
    const senhaClient = btoa("appweb:w3bcl13nt");
    const headers = new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("Authorization", `Basic ${senhaClient}`);

    const body = `username=${login}&password=${password}&grant_type=password`;

    return this.http
      .post<any>(this.url, body, { headers, withCredentials: true })
      .toPromise()
      .then((response) => {
        if (!response.idoperador) {
          return Promise.reject("Usuário não definido como operador");
        }
        const jwtPayload = this.jwtHelper.decodeToken(response.access_token);

        const isAdmin =
          jwtPayload && jwtPayload?.authorities?.includes(RULE_ADMIN);

        return isAdmin;
      })
      .catch((response) => {
        if (response.status === 400) {
          if (response.error && response.error.error === "invalid_grant") {
            return Promise.reject("Usuário ou senha inválida!");
          }
        }

        return Promise.reject(response);
      });
  }

  async logout(): Promise<void> {
    return this.http
      .delete(`${environment.apiUrl}/tokens/revoke`, { withCredentials: true })
      .toPromise()
      .then(() => {
        this.clearAccessToken();
      });
  }

  isAccessTokenInvalid(): boolean {
    const token = localStorage.getItem(StorageKeys.AUTH_TOKEN);

    return !token || this.jwtHelper.isTokenExpired(token, 20 * 60);
  }

  haveAnyPermission(roles: any[]): boolean {
    for (const role of roles) {
      if (this.havePermission(role)) {
        return true;
      }
    }

    return false;
  }

  havePermission(permission: string) {
    return (
      this.jwtPayload && this.jwtPayload?.authorities?.includes(permission)
    );
  }

  isAdmin(): boolean {
    return this.havePermission("ROLE_ADMIN");
  }

  podeRealizarOperacao(rule: string): boolean {
    return this.havePermission(rule) || this.isAdmin();
  }

  podeRealizarDevolucao(): boolean {
    return this.havePermission("VENDA DEVOLUÇÃO_INSERIR") || this.isAdmin();
  }

  bloquearVendaPorLimiteCredito(): boolean {
    return (
      this.jwtPayload?.bloquearVendaPorLimiteCredito &&
      this.jwtPayload?.bloquearVendaPorLimiteCredito === "S"
    );
  }

  getNewAccessToken(): Promise<void> {
    const senhaClient = btoa("appweb:w3bcl13nt");
    const headers = new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("Authorization", `Basic ${senhaClient}`);

    const body = "grant_type=refresh_token";

    return this.http
      .post<any>(this.url, body, { headers, withCredentials: true })
      .toPromise()
      .then((response) => {
        this.storeToken(response.access_token);
        return Promise.resolve(null);
      })
      .catch((response) => {
        console.error("Erro ao renovar token.", response);
        return Promise.resolve(null);
      });
  }

  toggleRememberMe(): void {
    this.rememberMe = !this.rememberMe;
    window.localStorage.setItem(
      StorageKeys.REMEMBER_ME,
      this.rememberMe.toString()
    );
    if (!this.rememberMe) {
      window.localStorage.removeItem(StorageKeys.USER_EMAIL);
      window.localStorage.removeItem(StorageKeys.USER_PASSWORD);
    }
  }

  setRememberMe(user: { email: string; password: string }): void {
    if (this.rememberMe) {
      window.localStorage.setItem(
        StorageKeys.USER_EMAIL,
        Base64.encode(user.email)
      );
      window.localStorage.setItem(
        StorageKeys.USER_PASSWORD,
        Base64.encode(user.password)
      );
    }
  }

  getRememberMe(): { email: string; password: string } {
    if (!this.rememberMe) {
      return null;
    }
    return {
      email: Base64.decode(window.localStorage.getItem(StorageKeys.USER_EMAIL)),
      password: Base64.decode(
        window.localStorage.getItem(StorageKeys.USER_PASSWORD)
      ),
    };
  }

  getIdEmpresa(): number {
    return this.jwtPayload.idempresa;
  }

  getMaxDescont(): number {
    return this.jwtPayload.descontoVenda;
  }

  blockSaleOfNegativeStock(): boolean {
    return this.isAdmin() || this.jwtPayload.estoqueNegativo === true;
  }

  priceChange() {
    return (
      this.isAdmin() ||
      (this.jwtPayload.alteraPrecoNaVenda &&
        this.jwtPayload.alteraPrecoNaVenda === "S")
    );
  }

  clearAccessToken() {
    localStorage.removeItem(StorageKeys.AUTH_TOKEN);
    localStorage.removeItem(StorageKeys.MOVIMENT);
    localStorage.removeItem(StorageKeys.MOVIMENT_KEY);
    this.jwtPayload = null;
  }

  private storeToken(token: string) {
    this.jwtPayload = this.jwtHelper.decodeToken(token);
    localStorage.setItem(StorageKeys.AUTH_TOKEN, token);
  }

  private loadToken() {
    const token = localStorage.getItem(StorageKeys.AUTH_TOKEN);

    if (token) {
      this.storeToken(token);
    }
  }
}
