import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { estados, estadosCidade } from 'src/app/util/constantes';
import { ClientService } from 'src/app/view/pdv/services/client.service';
import { ClienteCad } from '../../cliente-cad';
import { ErrorHandlerService } from '../../services/error-handler.service';

@Component({
  selector: 'app-cad-client-resum',
  templateUrl: './cad-client-resum.component.html',
  styleUrls: ['./cad-client-resum.component.scss']
})
export class CadClientResumComponent implements OnInit {

  @Input() display = false;
  @Output() eventCadClient = new EventEmitter();
  @Output() eventCloser = new EventEmitter();

  typePesson = [
    { label: 'Fisica', value: 'FISICA' },
    { label: 'Juridica', value: 'JURIDICA' }
  ];

  cidades = [];
  city: any;
  ufs = estados;


  client = new ClienteCad();

  constructor(
    private clientService: ClientService,
    private messageService: MessageService,
    private error: ErrorHandlerService
  ) { }

  ngOnInit(): void {
  }

  searchCity(event) {

    const ufs = estadosCidade.filter(c => c.sigla === this.client.uf);
    this.cidades = [];


    ufs[0].cidades.filter((c) => {
      c.nome.includes(event.query);
    });

    ufs[0].cidades.forEach(c => {
      if (c.nome.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
        this.cidades.push(c);
      }
    });
  }

  citySelected(event) {
    this.client.cidade = event.nome;
    this.client.codigoIbgeCidade = event.ibge;
  }

  save() {
    this.clientService.createClient(this.client)
      .then(result => {
        this.client = new ClienteCad();
        this.eventCadClient.emit({ client: result });
        this.closer();

        this.messageService.add({ severity: 'success', summary: 'Info', detail: 'Cliente salvo com sucesso' });
      })
      .catch(error => this.error.handleError(error));
  }

  closer() {
    this.display = false;
    this.eventCloser.emit(false);
  }

}
