<div id="print" class="container" style="display: none">
  <table cellpadding="0" cellspacing="0" width="100%">
    <tr>
      <td *ngIf="this.company.logo">
        <div style="display: block; text-align: center; padding: 10px">
          <img [src]="this.company.logo" width="150rem" />
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div id="empresa" style="text-align: center">
          <b>{{ company.nomeFantasia }}</b>
        </div>
        <div><b>CNPJ: </b>{{ company.cnpj }}</div>
        <div>
          {{ company.logradouro }},{{ company.numero }},{{ company.bairro }}
        </div>
        <div>{{ company.cep }} - {{ company.cidade }} - {{ company.uf }}</div>
        <div><b>Telefone:</b> {{ company.telefone }}</div>
        <div><b>Vendedor:</b> {{ sale.nomeVendedor }}</div>
      </td>
    </tr>
  </table>

  <div>
    <div
      style="
        display: flex;
        background: #e8e8e8;
        border-bottom: 2px solid #000;
        border-top: 2px solid #000;
        padding: 5px 0 5px 0;
        margin-top: 5px;
      "
    >
      <span style="width: 50%"> PV - {{ sale.id }} </span>
      <span style="width: 50%; text-align: right">
        <b>{{
          sale.dataHoraVenda | date : "dd/MM/yyyy HH:mm:ss" : "UTC -3 "
        }}</b>
      </span>
    </div>
  </div>

  <div>
    <table cellpadding="0" cellspacing="0" width="100%">
      <tr>
        <td>
          <b>CPF/CNPJ</b>
        </td>
        <td style="text-align: left; margin-left: 5px">
          {{ " " }}{{ sale.cpfCnpjCliente }}
        </td>
      </tr>
      <tr>
        <td>
          <b>Cliente</b>
        </td>
        <td style="text-align: left; margin-left: 5px">
          <span *ngIf="sale.nomeCliente !== 'Consumidor Final'"
            >{{ sale.cliente.id }} -
          </span>
          {{ sale.nomeCliente }}
        </td>
      </tr>
      <tr>
        <td style="width: 78px">
          <b>endereço</b>
        </td>
        <td style="text-align: left; margin-left: 5px">
          <span *ngIf="client"
            >{{ client?.logradouro }} - {{ client?.numero }}</span
          >
        </td>
      </tr>
      <tr>
        <td>
          <b>Cidade</b>
        </td>
        <td style="text-align: left; margin-left: 5px">
          <span *ngIf="client">{{ client?.cidade }}-{{ client?.uf }}</span>
        </td>
      </tr>
      <tr>
        <td>
          <b>CEP</b>
        </td>
        <td style="text-align: left; margin-left: 5px">
          <span *ngIf="client">{{ client?.cep }}</span>
        </td>
      </tr>
    </table>
  </div>

  <div>
    <table cellpadding="0" cellspacing="0" width="100%">
      <tr style="background: #e8e8e8">
        <th style="border-bottom: 1px solid #000; border-top: 1px solid #000">
          <b>Produto</b>
        </th>

        <th
          style="
            padding: 5px 0 5px 0;
            text-align: center;
            border-bottom: 1px solid #000;
            border-top: 1px solid #000;
          "
        >
          <b>Qtd.</b>
        </th>

        <th
          style="
            padding: 5px 0 5px 0;
            text-align: center;
            border-bottom: 1px solid #000;
            border-top: 1px solid #000;
          "
        >
          <b></b>
        </th>

        <th
          style="
            padding: 5px 0 5px 0;
            text-align: right;
            border-bottom: 1px solid #000;
            border-top: 1px solid #000;
          "
        >
          <b>VL. UNIT.</b>
        </th>

        <th
          style="
            text-align: right;
            border-bottom: 1px solid #000;
            border-top: 1px solid #000;
          "
        >
          <b>Total</b>
        </th>
      </tr>

      <ng-template
        ngFor
        let-row
        [ngForOf]="sale.listaPdvVendaDetalhe"
        let-i="index"
      >
        <tr>
          <td colspan="5">
            <strong *ngIf="row.codigo">{{ row.codigo }}</strong>
            <strong *ngIf="!row.codigo">{{ row?.produto?.id }}</strong>
            - {{ row?.produto?.nome }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td style="text-align: center">
            {{ row.quantidade | number : "1.3-3" }} {{ row.un ? row.un : "UN" }}
          </td>
          <td style="text-align: center">X</td>
          <td style="text-align: right">
            {{ row.valorUnitario | number : "1.2-2" }}
          </td>
          <td style="text-align: right">
            {{ row.valorTotal | number : "1.2-2" }}
          </td>
        </tr>
        <tr *ngIf="row.valorDesconto && row.valorDesconto > 0">
          <td style="text-align: center" colspan="5">
            Desconto no item {{ i + 1 }} -{{
              row.valorDesconto | number : "1.2-2"
            }}
          </td>
        </tr>
      </ng-template>
    </table>
    <hr />
    <table cellpadding="0" cellspacing="0" width="100%">
      <tr>
        <td>Subtotal</td>
        <td style="text-align: right">
          {{ sale.valorSubtotal | number : "1.2-2" }}
        </td>
      </tr>
      <tr>
        <td>Desconto</td>
        <td style="text-align: right">
          {{ sale.valorDesconto | number : "1.2-2" }}
        </td>
      </tr>
      <tr>
        <td><strong> Total</strong></td>
        <td style="text-align: right">
          <strong>
            {{ sale.valorTotal | number : "1.2-2" }}
          </strong>
        </td>
      </tr>
      <tr>
        <td><strong> Total Itens</strong></td>
        <td style="text-align: right">
          <strong>
            {{ totalItens | number : "1.2-2" }}
          </strong>
        </td>
      </tr>
      <tr>
        <td>Troco</td>
        <td style="text-align: right">
          {{ sale.troco | number : "1.2-2" }}
        </td>
      </tr>

      <tr>
        <td colspan="4">
          <hr />
        </td>
      </tr>
    </table>
    <table cellpadding="0" cellspacing="0" width="100%">
      <tr>
        <td width="60%">
          <b>Forma de pagamento</b>
        </td>
        <td style="text-align: right">
          <b>Valor</b>
        </td>
      </tr>

      <ng-template ngFor let-pag [ngForOf]="sale.listaFormaPagamento">
        <tr>
          <td>
            {{ pag?.tipoPagamento?.descricao }}
            {{
              pag.forma === "03" || pag.forma === "14"
                ? "(" + pag.qtdParcela + "x)"
                : ""
            }}
          </td>
          <td style="text-align: right">
            {{
              (pag.forma === "03" || pag.forma === "14"
                ? pag.valor / pag.qtdParcela
                : pag.valor
              ) | number : "1.2-2"
            }}
          </td>
        </tr>
        <tr *ngIf="pag.numeroAutorizacao">
          <td colspan="2">
            <small>NSU - {{ pag.numeroAutorizacao }}</small>
          </td>
        </tr>
        <tr>
          <td colspan="2" style="border-bottom: 1px solid #000"></td>
        </tr>
      </ng-template>

      <tr>
        <td colspan="4">
          <hr />
        </td>
      </tr>
    </table>
  </div>
  <ng-template [ngIf]="parcelas">
    <p style="text-align: center">
      <b> Parcelas</b>
    </p>
    <table cellpadding="0" cellspacing="0" width="100%">
      <thead>
        <th style="border-bottom: 1px solid #000">Número</th>
        <th style="text-align: center; border-bottom: 1px solid #000">
          Vencimento
        </th>
        <th style="text-align: right; border-bottom: 1px solid #000">Valor</th>
      </thead>
      <tbody>
        <tr *ngFor="let row of parcelas; index as i; last as isLast">
          <td
            [ngStyle]="{ 'border-bottom': isLast ? '1px solid #000' : 'none' }"
            style="text-align: left; padding: 3px"
          >
            {{ row.numero }}
          </td>
          <td
            style="text-align: center; padding: 3px"
            [ngStyle]="{ 'border-bottom': isLast ? '1px solid #000' : 'none' }"
          >
            {{ row.vencimento | date : "dd/MM/yyyy" }}
          </td>
          <td
            style="text-align: right; padding: 3px"
            [ngStyle]="{ 'border-bottom': isLast ? '1px solid #000' : 'none' }"
          >
            {{ row.valor | number : "1.2-2" }}
          </td>
        </tr>
      </tbody>
    </table>
  </ng-template>

  <br />

  <div *ngIf="sale.observacao">
    <b>Observação</b>
    <p style="word-wrap: break-word">{{ sale.observacao }}</p>
  </div>

  <br />
  <br />
  <p style="text-align: center">
    <b>_________________________________________</b>
  </p>
  <p style="text-align: center">
    <b>Cliente</b>
  </p>
  <div style="text-align: center">*** Este não é documento fiscal ***</div>
  <br />

  <div style="text-align: center">OBRIGADO E VOLTE SEMPRE!</div>
  <p style="text-align: center; padding: 15px">
    <button
      class="btn-print"
      onClick="window.print()"
      style="width: 100px; height: 30px"
    >
      Imprimir
    </button>
  </p>
</div>
