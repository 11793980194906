import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgxCurrencyModule } from "ngx-currency";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CheckboxModule } from "primeng/checkbox";
import { ContextMenuModule } from "primeng/contextmenu";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { InputMaskModule } from "primeng/inputmask";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { KeyFilterModule } from "primeng/keyfilter";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { SplitButtonModule } from "primeng/splitbutton";
import { TableModule } from "primeng/table";
import { TieredMenuModule } from "primeng/tieredmenu";
import { CadClientResumComponent } from "./components/cad-client-resum/cad-client-resum.component";
import { CardFormComponent } from "./components/card-form/card-form.component";
import { FooterComponent } from "./components/card/card-footer.componet";
import { CardComponent } from "./components/card/card.component";
import { ContainerInputComponent } from "./components/container-input/container-input.component";

import { InputNumberModule } from "primeng/inputnumber";
import { LoginPermissionComponent } from "./components/login-permission/login-permission.component";
import { CnpjDirective } from "./directives/cnpj.directive";
import { CpfDirective } from "./directives/cpf.directive";
import { TabDirective } from "./directives/tab.directive";
import { CompleteWithZerosPipe } from "./pipes/complete-with-zeros";

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ".",
  nullable: true,
};

@NgModule({
  imports: [
    FormsModule,
    DialogModule,
    CommonModule,
    RouterModule,
    ButtonModule,
    DropdownModule,
    InputTextModule,
    InputNumberModule,
    InputMaskModule,
    AutoCompleteModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    CheckboxModule,
    ReactiveFormsModule,
  ],
  declarations: [
    CompleteWithZerosPipe,
    CardComponent,
    FooterComponent,
    CardFormComponent,
    ContainerInputComponent,
    TabDirective,
    CnpjDirective,
    CpfDirective,
    CadClientResumComponent,
    LoginPermissionComponent,
  ],
  exports: [
    FormsModule,
    TableModule,
    CommonModule,
    TabDirective,
    ButtonModule,
    CardComponent,
    CalendarModule,
    DropdownModule,
    InputTextModule,
    FooterComponent,
    TieredMenuModule,
    CheckboxModule,
    NgxCurrencyModule,
    SplitButtonModule,
    AutoCompleteModule,
    OverlayPanelModule,
    InputTextareaModule,
    ReactiveFormsModule,
    CompleteWithZerosPipe,
    CadClientResumComponent,
    ContainerInputComponent,
    InputNumberModule,
    ContextMenuModule,
    KeyFilterModule,
    CalendarModule,
    DialogModule,
    InputMaskModule,
    CnpjDirective,
    CpfDirective,
    LoginPermissionComponent,
  ],
})
export class SharedModule {}
