import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageKeys } from '../storage-keys';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = localStorage.getItem(StorageKeys.AUTH_TOKEN);

    if (token && this.isUrlPermitida(request.url)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });

    }
    return next.handle(request);
  }

  private isUrlPermitida(url) {
    return url.indexOf('/oauth/token') === -1
      && url.indexOf('www.receitaws.com.br') === -1;
  }
}
