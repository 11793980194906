<div
  class="layout-wrapper"
  [class.mobile-active]="menuMobileActive"
  (click)="onLayoutClick()"
>
  <app-top-bar
    (eventToggle)="onToggle($event)"
    [tooggle]="toogleToobar"
    *ngIf="!isDysplayLogin"
  >
  </app-top-bar>

  <div
    class="layout-menu"
    (click)="onMenuClick($event)"
    *ngIf="!isDysplayPrint"
  >
    <app-layout-menu [displayLogin]="isDysplayLogin"></app-layout-menu>
  </div>
  <div class="layout-content" id="layout-content">
    <router-outlet></router-outlet>
  </div>
  <div class="layout-mask"></div>
</div>

<app-loading></app-loading>

<p-confirmDialog header="Confirmação" icon="fa fa-question-circle" #confirmacao>
  <p-footer>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      label="Sim"
      (click)="confirmacao.accept()"
    ></button>
    <button
      type="button"
      pButton
      icon="pi pi-times"
      class="p-button-secondary"
      label="Não"
      (click)="confirmacao.reject()"
    ></button>
  </p-footer>
</p-confirmDialog>
<p-toast></p-toast>
