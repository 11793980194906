import { NgModule } from "@angular/core";
import { CardModule } from "primeng/card";
import { SharedModule } from "../shared/shared.module";
import { LoginComponent } from "./components/login/login.component";
import { SecutiryRoutingModule } from "./security-routing.module";

@NgModule({
  imports: [SharedModule, SecutiryRoutingModule, CardModule],
  declarations: [LoginComponent],
})
export class SecurityModule {}
