<div class="p-d-flex p-justify-center">
  <div class="logo p-m-2">
    <a href="https://chronosinfo.com.br" target="blank">
      <img alt="Logo" src="assets/images/logo2x.png" alt="chronosinfo" />
    </a>
  </div>
</div>
<div class="p-d-flex p-justify-center">
  <p-card
    header="Login"
    [style]="{
      width: '400px',
      background: 'rgba(0, 0, 0, 0.5)',
      color: '#fff'
    }"
  >
    <div class="card flex flex-column md:flex-row gap-2">
      <div
        class="card flex justify-content-center"
        style="background-color: rgba(0, 0, 0, 0.5)"
      >
        <form autocomplete="off" [formGroup]="loginForm" (submit)="onSubmit()">
          <div class="p-inputgroup p-mb-3">
            <span><i class="pi pi-user"></i></span>
            <input
              type="text"
              pInputText
              placeholder="Login"
              formControlName="email"
            />
          </div>

          <div class="p-inputgroup p-mb-3">
            <span><i class="pi pi-key"></i></span>
            <input
              type="password"
              pInputText
              placeholder="Senha"
              formControlName="password"
            />
          </div>

          <div class="p-grid">
            <input
              type="checkbox"
              class="remember"
              [checked]="auth.rememberMe"
              (change)="onRememberMe()"
            />Lembrar Senha
          </div>
          <div style="text-align: right">
            <p-button
              type="submit"
              [disabled]="!loginForm.valid || submitting"
              label="Login"
              styleClass="p-button-danger"
            ></p-button>
          </div>
        </form>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <div class="p-d-flex" style="flex-direction: column">
        <strong>Chronosinfo Softwares Comercial</strong>
        <strong>(61) 4103-2540</strong>
      </div>
    </ng-template>
  </p-card>
</div>
