import { ParamsService } from './params.service';
import { Params } from './../models/params';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, share } from 'rxjs/operators';
import { Empresa } from '../models/empresa.model';
import { EmpresaService } from './empresa.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {


  private empresa: Observable<Empresa>;
  private params: Observable<Params>;

  constructor(private empresaService: EmpresaService, private paramsService: ParamsService) { }


  clearEmpresa() {
    this.empresa = null;
  }

  getEmpresa() {
    if (this.empresa) {
      return this.empresa;
    } else {
      return this.empresaService.getEmpresa()
        .pipe(
          tap(result => {
            this.empresa = of(Empresa.fromJson(result));
            return this.empresa;
          }),
          share()
        );

    }
  }

  getParams(idCompany: number) {
      return this.paramsService.getParams(idCompany)
        .pipe(
          tap(result => {
            this.params = of(result);
            return this.params;
          }),
          share()
        );

  }
}
