<p-dialog
  header="Login"
  [(visible)]="displayLogin"
  [modal]="true"
  [responsive]="true"
  [style]="{ minWidth: '360px' }"
  [minY]="70"
  [contentStyle]="{
    overflow: 'visible',
    'max-width': '800px',
    'max-height': '60vh',
    'min-height': '150px'
  }"
  [baseZIndex]="1000"
  (onHide)="onHide()"
>
  <div class="card flex flex-column md:flex-row gap-2">
    <div class="card flex justify-content-center">
      <form autocomplete="off" [formGroup]="loginForm" (submit)="onSubmit()">
        <div class="p-inputgroup p-mb-3">
          <span><i class="pi pi-user"></i></span>
          <input
            type="text"
            pInputText
            placeholder="Login"
            formControlName="email"
          />
        </div>

        <div class="p-inputgroup p-mb-3">
          <span><i class="pi pi-key"></i></span>
          <input
            type="password"
            pInputText
            placeholder="Senha"
            formControlName="password"
          />
        </div>
        <div style="text-align: right">
          <p-button
            type="submit"
            [disabled]="!loginForm.valid || submitting"
            label="Login"
            styleClass="p-button-danger"
          ></p-button>
        </div>
      </form>
    </div>
  </div>
</p-dialog>
