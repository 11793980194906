import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { CnpjValidator } from '../validators/cnpj.validator';

@Directive({
  selector: '[appCnpj]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CnpjDirective,
    multi: true
  }]
})
export class CnpjDirective implements Validator {

  /**
   * Valida os dados.
   *
   * @param AbstractControl control
   * @return object ou null caso válido
   */
  validate(control: AbstractControl): {[key: string]: any} {
    return CnpjValidator.validate(control);
  }

}
