import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent {

  @Input() tooggle = false;

  @Output() eventToggle = new EventEmitter();

  constructor() { }

  onToggle(event) {
    this.tooggle = !this.tooggle;
    this.eventToggle.emit(this.tooggle);
    event.preventDefault();
  }

}
