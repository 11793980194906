import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

import { CupomPrintComponent } from "./view/cupom-print/cupom-print.component";
import { SaleResolveService } from "./view/cupom-print/services/sale-resolve.service";

const routes: Routes = [
  {
    path: "pdv",
    loadChildren: () =>
      import("./view/pdv/pdv.module").then((m) => m.PdvModule),
  },
  {
    path: "budgets",
    loadChildren: () =>
      import("./view/budget/budget.module").then((m) => m.BudgetModule),
  },
  {
    path: "sales",
    loadChildren: () =>
      import("./view/sales/sales.module").then((m) => m.SalesModule),
  },
  {
    path: "print/:id",
    component: CupomPrintComponent,
    resolve: { sale: SaleResolveService },
  },
  {
    path: "operations",
    loadChildren: () =>
      import("./view/operations/operations.module").then(
        (m) => m.OperationsModule
      ),
  },
  {
    path: "caixa",
    loadChildren: () =>
      import("./view/cashier/cashier.module").then((m) => m.CashierModule),
  },
  {
    path: "receipt",
    loadChildren: () =>
      import("./view/financial/financial.module").then(
        (m) => m.FinancialModule
      ),
  },
  { path: "", redirectTo: "login", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
