import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ChronosHttp } from "src/app/security/chronos-http";
import { Page } from "src/app/shared/models/page";
import { PdvVendaCabecalhoDTO } from "src/app/shared/models/venda";
import { VendaPdvResum } from "src/app/shared/models/venda-pdv-resum";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SaleService {
  url: string;

  constructor(private http: ChronosHttp) {
    this.url = `${environment.apiUrl}/pdv/vendas`;
  }

  finish(sale: PdvVendaCabecalhoDTO): Promise<PdvVendaCabecalhoDTO> {
    const newSale = JSON.parse(JSON.stringify(sale));

    if (newSale.cliente && newSale.cliente.id) {
      newSale.idcliente = newSale.cliente.id;
      delete newSale.cliente;
    }

    if (newSale.vendedor) {
      delete newSale.vendedor;
    }

    newSale.listaPdvVendaDetalhe.forEach((i) => {
      const produto = {
        id: i.produto.id,
        nome: i.produto.nome || i.produto.descricaoPdv,
      };
      delete i.produto;
      delete i.un;
      i.produto = produto;
      i.valorDesconto = parseFloat(i.valorDesconto || 0).toFixed(2);
      i.valorSubtotal = parseFloat(i.valorSubtotal).toFixed(2);
      i.valorTotal = parseFloat(i.valorTotal).toFixed(2);
      i.taxaDesconto = parseFloat(i.taxaDesconto || 0).toFixed(2);
      i.valorDesconto = parseFloat(i.valorDesconto || 0).toFixed(2);
      i.taxaComissao = parseFloat(i.taxaComissao || 0).toFixed(2);
      i.valorComissao = parseFloat(i.valorComissao || 0).toFixed(2);
    });

    newSale.listaFormaPagamento.forEach((p) => {
      p.valor = parseFloat(p.valor).toFixed(2);
      p.troco = parseFloat(p.troco).toFixed(2);
    });

    if (newSale.taxaComissao ?? newSale.taxaComissao !== 0) {
      newSale.valorComissao =
        (newSale.valorSubtotal - newSale.valorDesconto) / newSale.taxaComissao;
    }

    newSale.valorSubtotal = parseFloat(newSale.valorSubtotal).toFixed(2);
    newSale.taxaDesconto = parseFloat(newSale.taxaDesconto || 0).toFixed(2);
    newSale.valorDesconto = parseFloat(newSale.valorDesconto || 0).toFixed(2);
    newSale.valorTotal = parseFloat(newSale.valorTotal).toFixed(2);
    newSale.valorComissao = parseFloat(newSale.valorComissao || 0).toFixed(2);
    newSale.troco = parseFloat(newSale.troco || 0).toFixed(2);

    return this.http
      .post<PdvVendaCabecalhoDTO>(`${this.url}`, newSale)
      .toPromise();
  }

  findAll(
    idmovimento: number,
    idempresa: number
  ): Promise<Page<VendaPdvResum>> {
    const params = new HttpParams({
      fromObject: {
        idmovimento,
        idempresa,
        page: "0",
        size: "100",
      },
    });

    return this.http
      .get<Page<VendaPdvResum>>(`${this.url}`, { params })
      .toPromise();
  }

  findById(id: number): Promise<any> {
    return this.http.get<any>(`${this.url}/${id}`).toPromise();
  }

  findOrcamentoById(id: number): Promise<PdvVendaCabecalhoDTO> {
    return this.http.get<any>(`${this.url}/importacao/${id}`).toPromise();
  }

  faturarNfce(id: number): Promise<PdvVendaCabecalhoDTO> {
    return this.http
      .put<PdvVendaCabecalhoDTO>(`${this.url}/faturar/${id}`, "")
      .toPromise();
  }

  faturarNfe(id: number): Promise<PdvVendaCabecalhoDTO> {
    return this.http
      .put<PdvVendaCabecalhoDTO>(`${this.url}/faturar/55/${id}`, "")
      .toPromise();
  }

  cancelar(id: number): Promise<void> {
    return this.http
      .put<void>(
        `${this.url}/cancelar/${id}`,
        "erro na digitacao da nota fiscal"
      )
      .toPromise();
  }

  danfe(id: number) {
    return this.http
      .get(`${environment.apiUrl}/relatorios/comercial/nfe/danfe/${id}`, {
        responseType: "blob",
      })
      .toPromise();
  }

  print(id: number) {
    const params = new HttpParams({
      fromObject: {
        id: id.toString(),
      },
    });

    return this.http
      .get(`${environment.apiUrl}/relatorios/comercial/vendas/pdv`, {
        params,
        responseType: "blob",
      })
      .toPromise();
  }
}
