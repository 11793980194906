import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { NotAuthenticatedError } from "src/app/security/chronos-http";

@Injectable({
  providedIn: "root",
})
export class ErrorHandlerService {
  constructor(private messageService: MessageService, private router: Router) {}

  handleError(errorResponse: HttpErrorResponse | any) {
    let msg: string;

    if (typeof errorResponse === "string") {
      msg = errorResponse;
    } else if (errorResponse instanceof NotAuthenticatedError) {
      msg = "Sua sessão expirou!";
      this.router.navigate(["/login"]);
    } else if (errorResponse instanceof HttpErrorResponse) {
      if (errorResponse.status >= 400 && errorResponse.status <= 499) {
        msg = "Ocorreu um erro ao processar a sua solicitação";

        if (errorResponse.status === 403) {
          msg = "Você não tem permissão para executar esta ação";
        }

        try {
          if (
            errorResponse.url &&
            errorResponse.url.includes(
              "/comercial/movimentos-caixa/operador"
            ) &&
            errorResponse.status === 404
          ) {
            this.router.navigate(["/caixa"]);
            return;
          } else if (errorResponse.error) {
            msg = errorResponse.error.userMessage;

            if (
              errorResponse.error.objects &&
              errorResponse.error.objects.length > 0
            ) {
              errorResponse.error.objects.forEach((err) => {
                msg += "\n";
                msg += ` para o campo ${err.name} ` + err.userMessage;
              });
            }
          }
        } catch (e) {}
      } else if (errorResponse.status === 500) {
        try {
          msg = errorResponse.error.userMessage;
          //  console.error('Ocorreu um erro', errorResponse);
          //  this.router.navigate(['/sistema/erro']);
          //  return;
        } catch (e) {}
      } else {
        msg = "Erro ao processar serviço remoto. Tente novamente.";
      }

      console.error("Ocorreu um erro", errorResponse);
    } else {
      msg = "Erro ao processar serviço remoto. Tente novamente.";
      console.error("Ocorreu um erro", errorResponse);
    }

    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: msg,
    });
  }
}
