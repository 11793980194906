import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { VendaItem } from "src/app/shared/models/venda-item";
import { ParcelaPagamento } from "src/app/shared/models/venda-pagameto";
import { EmpresaService } from "src/app/shared/services/empresa.service";
import { GlobalDataService } from "src/app/shared/services/global-data.service";
import { toUserLocalDate } from "src/app/util/bibioteca";
import { printReport } from "src/app/util/print";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-cupom-print",
  templateUrl: "./cupom-print.component.html",
  styleUrls: ["./cupom-print.component.scss"],
})
export class CupomPrintComponent implements OnInit {
  sale?: any;
  company: any;

  client?: any;

  parcelas: ParcelaPagamento[];
  totalItens: number;
  logoLoaded: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private empresaService: EmpresaService,
    private globalDataService: GlobalDataService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(async (data) => {
      this.sale = data["sale"];
      this.sale.dataHoraVenda = toUserLocalDate(this.sale.dataHoraVenda);
      this.client = this.sale.cliente;

      this.company = await this.globalDataService.getEmpresa().toPromise();

      const nameLogo = this.company?.imagemLogotipo?.split("/").pop() ?? "";

      if (nameLogo) {
        const logo = await this.empresaService
          .getLogoEmpresa(nameLogo)
          .toPromise();
        this.company.logo = logo;
      }
      this.logoLoaded = true;

      this.updateParcelas();

      this.totalItens =
        this.sale?.listaPdvVendaDetalhe.length > 0
          ? this.calcularTotaisItens(this.sale?.listaPdvVendaDetalhe)
          : 0;
    });
  }

  ngAfterViewInit(): void {
    this.printReportWhenLogoLoaded();
  }

  private calcularTotaisItens(itens: VendaItem[]): number {
    return itens
      .map((e) => e.quantidade)
      .reduce((oldValue, newValue) => oldValue + newValue);
  }

  private updateParcelas(): void {
    const pags =
      this.sale?.listaFormaPagamento.filter(
        (p) => p.tipoPagamento.geraParcelas === "SIM"
      ) ?? [];

    if (pags.length > 0) {
      this.parcelas = pags.reduce(
        (parcelas, f) => [...f.parcelas, ...parcelas],
        []
      );
    }
  }

  private async printReportWhenLogoLoaded(): Promise<void> {
    await new Promise(() => {
      const intervalId = setInterval(() => {
        if (this.logoLoaded) {
          clearInterval(intervalId);
          const conteudo = document.getElementById("print");
          printReport(conteudo, false, environment.production);
        }
      }, 100);
    });
  }
}
