import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoadingIndicatorService } from '../../services/loading-indicator.service';


@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit, OnDestroy {


  loading: boolean;


  teste = true;

  loadingText = 'Carregando...';

  constructor(private loadingIndicatorService: LoadingIndicatorService) {
    setTimeout(() => {
      this.loadingIndicatorService
        .onLoadingChanged
        .subscribe(isLoading => {
          this.loading = isLoading;
          this.loadingIndicatorService.loading = isLoading;
        });
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.loading = false;
    this.loadingIndicatorService.loading = false;
  }


}
